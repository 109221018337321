"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var sequence_services_1 = require("../../core/services/sequence-services");
var routes_1 = require("../../routes");
var json_resources_1 = require("../../core/json-resources");
var ajax_services_1 = require("../../core/services/ajax-services");
var resource_api_1 = require("../../schemas/resource-api");
var isErrorResponse = resource_api_1.ResourceApi.isErrorResponse;
var bisect_1 = require("../../utils/bisect");
var chat_room_reducer_1 = require("./chat-room-reducer");
var room_list_reducer_1 = require("./room-list-reducer");
function goBack() {
    return {
        type: "go-back",
    };
}
exports.goBack = goBack;
function selectedCategory1Search(value) {
    return {
        type: "selected-category1-search",
        value: value
    };
}
exports.selectedCategory1Search = selectedCategory1Search;
function selectedCategory2(value) {
    return {
        type: "selected-category2",
        value: value
    };
}
exports.selectedCategory2 = selectedCategory2;
function reduceHeader(state, action) {
    var _a, _b, _c, _d, _e;
    var effects = [];
    switch (action.type) {
        case "complete-request":
            if (exports.selectedCategory1RequestName.includes(action.name[0])) {
                state = tslib_1.__assign({}, state);
                state.inputs = tslib_1.__assign({}, state.inputs);
                state.chat_categories = tslib_1.__assign({}, state.chat_categories);
                state.chat_categories.categories2 = tslib_1.__assign({}, state.chat_categories.categories2);
                var responseDetails = json_resources_1.getResourceResponseDetails(action);
                var apiResponse = responseDetails.apiResponse;
                if (!apiResponse || isErrorResponse(apiResponse))
                    break;
                if (responseDetails.resources) {
                    var chatCategoryData = responseDetails.resources[0];
                    if (chatCategoryData && chatCategoryData.attributes.length > 0 && chatCategoryData.type === "chat_categories") {
                        state.inputs.operatorSelectedCategories2Value = "";
                        state.chat_categories.categories2 = chatCategoryData.attributes;
                    }
                }
                var disabledFlg = action.name[1];
                if (disabledFlg && disabledFlg === "1") {
                    state.chat_categories_disabled = false;
                }
            }
            if (action.name[0] == exports.updateCategoryRequestName) {
                state = tslib_1.__assign({}, state);
                var disabledFlg = action.name[1];
                if (disabledFlg && disabledFlg === "1") {
                    state.chat_categories_disabled = false;
                }
            }
            break;
        case "go-back":
            state = tslib_1.__assign({}, state);
            var previousWidgetPages = state.previousWidgetPages.slice();
            var previousPage = (state.previousWidgetPages[0] && state.previousWidgetPages[0] === "room" && state.currentWidgetPage === "room") ? "roomList" : previousWidgetPages.pop();
            state.currentWidgetPage = previousPage;
            state.currentViewingRoomId = "";
            state.popupMessageIds = [];
            break;
        case "selected-category1-search":
            state = tslib_1.__assign({}, state);
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.chat_categories_disabled = true;
            state.inputs.operatorSelectedCategories2Value = "";
            if (state.currentViewingRoomId !== undefined
                && state.currentViewingRoomId !== null
                && state.currentViewingRoomId !== "") {
                (_a = categoriesUpdate(effects, state, state.currentViewingRoomId, false), state = _a.state, effects = _a.effects);
                var room_1 = bisect_1.Indexer.getFirstMatching(state.stores.rooms.byId, [state.currentViewingRoomId]);
                if (room_1) {
                    if (room_1.attributes.settings !== undefined && room_1.attributes.settings !== null) {
                        room_1.attributes.settings.chat_category1 = state.inputs.operatorSelectedCategories1Value;
                    }
                }
            }
            var sid = categorySid(state);
            if (sid !== state.operatorSelectedCategoriesSid) {
                state.operatorSelectedCategoriesSid = sid;
                state.scenarioEndSurveyDisplayedFlg = false;
                state.scenarioEndSurveyAnsweredFlg = false;
                state.scenarioTemplatesData = [];
                var roomId = null;
                if (state.currentViewingRoomId !== undefined && state.currentViewingRoomId !== null && state.currentViewingRoomId !== "") {
                    roomId = Number(state.currentViewingRoomId);
                }
                effects = sequence_services_1.sequence(effects, room_list_reducer_1.selectedCategory1(state.client_id, action.value));
            }
            else {
                (_b = selectedCategory1Action(effects, state, action.value, true), state = _b.state, effects = _b.effects);
            }
            break;
        case "selected-category2":
            state = tslib_1.__assign({}, state);
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.chat_categories_disabled = true;
            var sid = categorySid(state);
            var room = bisect_1.Indexer.getFirstMatching(state.stores.rooms.byId, [state.currentViewingRoomId]);
            if (room) {
                if (room.attributes.settings !== undefined && room.attributes.settings !== null) {
                    room.attributes.settings.chat_category2 = state.inputs.operatorSelectedCategories2Value;
                }
            }
            if (sid !== state.operatorSelectedCategoriesSid) {
                state.operatorSelectedCategoriesSid = sid;
                state.scenarioEndSurveyDisplayedFlg = false;
                state.scenarioEndSurveyAnsweredFlg = false;
                state.scenarioTemplatesData = [];
                var roomId = null;
                if (state.currentViewingRoomId !== undefined && state.currentViewingRoomId !== null && state.currentViewingRoomId !== "") {
                    roomId = Number(state.currentViewingRoomId);
                }
                (_c = categoriesUpdate(effects, state, state.currentViewingRoomId, false), state = _c.state, effects = _c.effects);
                (_d = sequence_services_1.sequenceReduction(effects, chat_room_reducer_1.scenarioSelectList(state, 0, roomId, sid)), state = _d.state, effects = _d.effects);
            }
            else {
                state.operatorSelectedCategoriesSid = sid;
                (_e = categoriesUpdate(effects, state, state.currentViewingRoomId, true), state = _e.state, effects = _e.effects);
            }
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceHeader = reduceHeader;
exports.selectedCategory1RequestName = "selected-category1-request-name";
exports.updateCategoryRequestName = "update-category-request-name";
function selectedCategory1Action(effects, state, value, disabledFlg) {
    state = tslib_1.__assign({}, state);
    state.inputs = tslib_1.__assign({}, state.inputs);
    effects = sequence_services_1.sequence(effects, searchCategories2(state, value, disabledFlg));
    return { state: state, effects: effects };
}
function searchCategories2(state, value, disabledFlg) {
    var query = {};
    query["chatCategory1"] = value;
    query["client_id"] = state.client_id;
    return json_resources_1.requestResourceFetch([exports.selectedCategory1RequestName, disabledFlg ? "1" : "0"], routes_1.RapiV1ScenarioTemplatesPath + "/seletced_category1", query);
}
function categoriesUpdate(effects, state, roomId, disabledFlg) {
    var query = {};
    state = tslib_1.__assign({}, state);
    if (roomId === undefined || roomId === null || roomId === "") {
        return { state: state, effects: effects };
    }
    query["chatCategory1"] = state.inputs.operatorSelectedCategories1Value;
    query["chatCategory2"] = state.inputs.operatorSelectedCategories2Value;
    query["roomId"] = roomId;
    var ajaxConfig = {};
    ajaxConfig.headers = json_resources_1.jsonResourceHeaders;
    ajaxConfig.url = routes_1.RapiV1RoomsPath + "/update_categories";
    ajaxConfig.method = "POST";
    ajaxConfig.json = query;
    effects = sequence_services_1.sequence(effects, ajax_services_1.requestAjax([exports.updateCategoryRequestName, disabledFlg ? "1" : "0"], ajaxConfig));
    return { state: state, effects: effects };
}
function categorySid(state) {
    var sid = 0;
    if (state.chat_categories.categories2 !== undefined
        && state.chat_categories.categories2 !== null
        && state.chat_categories.categories2.length > 0) {
        if (state.inputs.operatorSelectedCategories2Value !== undefined
            && state.inputs.operatorSelectedCategories2Value !== null
            && state.inputs.operatorSelectedCategories2Value !== "") {
            var selectCategory = state.chat_categories.categories2
                .filter(function (category) { return category.value === state.inputs.operatorSelectedCategories2Value; })
                .shift();
            if (selectCategory !== undefined && selectCategory !== null) {
                sid = selectCategory.sgid;
            }
        }
    }
    else if (state.chat_categories.categories1 !== undefined
        && state.chat_categories.categories1 !== null
        && state.chat_categories.categories1.length > 0) {
        if (state.inputs.operatorSelectedCategories1Value !== undefined
            && state.inputs.operatorSelectedCategories1Value !== null
            && state.inputs.operatorSelectedCategories1Value !== "") {
            var selectCategory = state.chat_categories.categories1
                .filter(function (category) { return category.value === state.inputs.operatorSelectedCategories1Value; })
                .shift();
            if (selectCategory !== undefined && selectCategory !== null) {
                sid = selectCategory.sgid;
            }
        }
    }
    return sid;
}
exports.categorySid = categorySid;
