"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var resource_api_1 = require("../schemas/resource-api");
var bisect_1 = require("../utils/bisect");
var ChatCategoriesStore;
(function (ChatCategoriesStore) {
    var assertInnerId = resource_api_1.ResourceApi.assertInnerId;
    ChatCategoriesStore.indexer = new bisect_1.Indexer("byId");
    ChatCategoriesStore.indexer.addIndex("byId", function (ChatCategoryData) { return [assertInnerId(ChatCategoryData)]; });
})(ChatCategoriesStore = exports.ChatCategoriesStore || (exports.ChatCategoriesStore = {}));
