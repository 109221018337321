"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var chat_room_reducer_1 = require("./chat-room-reducer");
var complete_request_reducer_1 = require("./complete-request-reducer");
var initial_loading_reducer_1 = require("./initial-loading-reducer");
var json_resources_1 = require("../../core/json-resources");
var routes_1 = require("../../routes");
var popup_messages_reducer_1 = require("../../reducers/popup-messages-reducer");
var sequence_services_1 = require("../../core/services/sequence-services");
var last_read_at_reducer_1 = require("../../reducers/last-read-at-reducer");
var bisect_1 = require("../../utils/bisect");
var resource_api_1 = require("../../schemas/resource-api");
var isErrorResponse = resource_api_1.ResourceApi.isErrorResponse;
var header_reducer_1 = require("./header-reducer");
function loadRoom(roomId) {
    return {
        type: "load-room",
        roomId: roomId,
    };
}
exports.loadRoom = loadRoom;
function createNewRoom() {
    return {
        type: "create-new-room",
    };
}
exports.createNewRoom = createNewRoom;
function loadOldRooms() {
    return {
        type: "load-old-rooms",
    };
}
exports.loadOldRooms = loadOldRooms;
function goToRoom(state, roomId) {
    var _a;
    var effects = [];
    state = tslib_1.__assign({}, state);
    state.previousWidgetPages = state.previousWidgetPages.concat(state.currentWidgetPage);
    state.currentWidgetPage = "room";
    state.currentViewingRoomId = roomId;
    state.inputs.operatorSelectedCategories1Value = "";
    state.inputs.operatorSelectedCategories2Value = "";
    effects = sequence_services_1.sequence(effects, chat_room_reducer_1.requestChatHistory(roomId));
    effects = sequence_services_1.sequence(effects, exports.requestFormInputs(roomId));
    effects = sequence_services_1.sequence(effects, popup_messages_reducer_1.requestRoomMembers(roomId));
    if (roomId) {
        var room = bisect_1.Indexer.getFirstMatching(state.stores.rooms.byId, [roomId]);
        if (room) {
            state.currentViewingRoomName = room.attributes.name;
            var scenarioFlg = false;
            if (state.plan_settings.scenario_chat_flg) {
                scenarioFlg = state.plan_settings.scenario_chat_flg;
            }
            if (scenarioFlg) {
                if (room.attributes.settings === undefined || room.attributes.settings === null) {
                    room.attributes.settings = {};
                }
                state.inputs.operatorSelectedCategories1Value = room.attributes.settings.chat_category1 || "";
                state.inputs.operatorSelectedCategories2Value = room.attributes.settings.chat_category2 || "";
            }
        }
    }
    (_a = sequence_services_1.sequenceReduction(effects, last_read_at_reducer_1.tryToMarkReadWidget(state)), state = _a.state, effects = _a.effects);
    return { state: state, effects: effects };
}
exports.goToRoom = goToRoom;
function reduceRoomList(state, action) {
    var _a;
    var effects = [];
    var scenarioFlg = false;
    if (state.plan_settings.scenario_chat_flg) {
        scenarioFlg = state.plan_settings.scenario_chat_flg;
    }
    switch (action.type) {
        case "load-room":
            var oldRoomId = state.currentViewingRoomId;
            state.scenarioEndSurveyDisplayedFlg = false;
            state.chat_categories_disabled = true;
            state.scenarioTemplatesData = [];
            (_a = sequence_services_1.sequenceReduction(effects, goToRoom(state, action.roomId)), state = _a.state, effects = _a.effects);
            if (scenarioFlg && action.roomId && oldRoomId !== action.roomId) {
                state = tslib_1.__assign({}, state);
                if (state.inputs.operatorSelectedCategories1Value !== undefined
                    && state.inputs.operatorSelectedCategories1Value !== null
                    && state.inputs.operatorSelectedCategories1Value !== "") {
                    effects = sequence_services_1.sequence(effects, exports.selectedCategory1(state.client_id, state.inputs.operatorSelectedCategories1Value));
                }
                else {
                    state.inputs.operatorSelectedCategories2Value = "";
                    state.chat_categories = {};
                    effects = sequence_services_1.sequence(effects, exports.defaultCategories(state.client_id));
                    var newData = chat_room_reducer_1.getScenarioTemplateData(state, state.currentViewingRoomId);
                    state = tslib_1.__assign({}, state, newData.state);
                    effects = sequence_services_1.sequence(effects, newData.effects);
                }
            }
            else {
                state.chat_categories_disabled = false;
            }
            break;
        case "create-new-room":
            state = tslib_1.__assign({}, state);
            state.previousWidgetPages = state.previousWidgetPages.concat(state.currentWidgetPage);
            state.currentWidgetPage = "room";
            state.currentViewingRoomId = "";
            state.scenarioTemplatesData = [];
            state.inputs.operatorSelectedCategories1Value = "";
            state.inputs.operatorSelectedCategories2Value = "";
            state.operatorSelectedCategoriesSid = 0;
            state.scenarioEndSurveyDisplayedFlg = false;
            if (scenarioFlg) {
                state.chat_categories = {};
                state.chat_categories_disabled = true;
                effects = sequence_services_1.sequence(effects, exports.defaultCategories(state.client_id));
                state.scenarioTemplatesData = [];
                var newData = chat_room_reducer_1.getScenarioTemplateData(state, null);
                state = tslib_1.__assign({}, state, newData.state);
                effects = sequence_services_1.sequence(effects, newData.effects);
            }
            break;
        case "load-old-rooms":
            effects = sequence_services_1.sequence(effects, complete_request_reducer_1.loadNextPageIfNeeded(state, initial_loading_reducer_1.loadCustomerRoomsRequestName));
            break;
        case "complete-request":
            if (action.name[0] === last_read_at_reducer_1.markReadRequestName) {
                var roomId = action.name[1];
                state.isRoomMarkingRead = tslib_1.__assign({}, state.isRoomMarkingRead);
                state.isRoomMarkingRead[roomId] = false;
            }
            if (action.name[0] === exports.getDefaultCategoriesRequestName) {
                if (!scenarioFlg)
                    break;
                state = tslib_1.__assign({}, state);
                state.chat_categories = {};
                var responseDetails = json_resources_1.getResourceResponseDetails(action);
                var apiResponse = responseDetails.apiResponse;
                if (!apiResponse || isErrorResponse(apiResponse))
                    break;
                if (responseDetails.resources) {
                    var chatCategoryAllData = responseDetails.resources[0];
                    if (chatCategoryAllData && chatCategoryAllData.type === "chat_all_categories") {
                        state.chat_categories = chatCategoryAllData.attributes.chat_categories;
                    }
                }
            }
            if (action.name[0] === exports.getSelectedCategory1RequestName) {
                if (!scenarioFlg)
                    break;
                state = tslib_1.__assign({}, state);
                state.chat_categories = tslib_1.__assign({}, state.chat_categories);
                var responseDetails = json_resources_1.getResourceResponseDetails(action);
                var apiResponse = responseDetails.apiResponse;
                if (!apiResponse || isErrorResponse(apiResponse))
                    break;
                state.chat_categories.categories2 = [];
                if (responseDetails.resources) {
                    var chatCategoryData = responseDetails.resources[0];
                    if (chatCategoryData && chatCategoryData.type === "chat_categories") {
                        state.chat_categories.categories2 = chatCategoryData.attributes;
                    }
                    state.scenarioTemplatesData = [];
                    var sid = header_reducer_1.categorySid(state);
                    var newData = chat_room_reducer_1.scenarioSelectList(state, 0, Number(state.currentViewingRoomId), sid);
                    state = tslib_1.__assign({}, state, newData.state);
                    effects = sequence_services_1.sequence(effects, newData.effects);
                }
            }
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceRoomList = reduceRoomList;
exports.requestFormInputs = function (roomId) {
    var query = {};
    query.include = ["survey"];
    return json_resources_1.requestResourceFetch([exports.getQuestionsRequestName, roomId], routes_1.RapiV1RoomsPath + "/" + roomId + "/questions", query);
};
exports.defaultCategories = function (client_id) {
    var query = {};
    query["client_id"] = client_id;
    return json_resources_1.requestResourceFetch([exports.getDefaultCategoriesRequestName], routes_1.RapiV1ScenarioTemplatesPath + "/default_categories", query);
};
exports.selectedCategory1 = function (client_id, chat_category1) {
    var query = {};
    query["client_id"] = client_id;
    query["chatCategory1"] = chat_category1;
    return json_resources_1.requestResourceFetch([exports.getSelectedCategory1RequestName], routes_1.RapiV1ScenarioTemplatesPath + "/seletced_category1", query);
};
exports.getQuestionsRequestName = "get-questions-request-name";
exports.getDefaultCategoriesRequestName = "get-default-categories-request-mame";
exports.getSelectedCategory1RequestName = "get-selected-category1-request-mame";
