"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var reducers_1 = require("../../core/reducers");
var json_resources_1 = require("../../core/json-resources");
var resource_api_1 = require("../../schemas/resource-api");
var newResource = resource_api_1.ResourceApi.newResource;
var isErrorResponse = resource_api_1.ResourceApi.isErrorResponse;
var routes_1 = require("../../routes");
var initial_loading_reducer_1 = require("./initial-loading-reducer");
var inputs_reducer_1 = require("../../reducers/subreducers/inputs-reducer");
var bisect_1 = require("../../utils/bisect");
var addRelationship = resource_api_1.ResourceApi.addRelationship;
var room_list_reducer_1 = require("./room-list-reducer");
var complete_request_reducer_1 = require("./complete-request-reducer");
var array_utils_1 = require("../../utils/array-utils");
var getRelationshipId = resource_api_1.ResourceApi.getRelationshipId;
var sequence_services_1 = require("../../core/services/sequence-services");
var action_cable_services_1 = require("../../core/services/action-cable-services");
var google_analytics_services_1 = require("../../core/services/google-analytics-services");
var paged_search_requests_reducer_1 = require("../../reducers/paged-search-requests-reducer");
var csv_service_1 = require("../../core/services/csv-service");
var show_video_tag_services_1 = require("../../core/services/show-video-tag-services");
var header_reducer_1 = require("./header-reducer");
function sendTextMessage(value) {
    return {
        type: "send-text-message",
        value: value
    };
}
exports.sendTextMessage = sendTextMessage;
function roomInput(input) {
    return { type: "room-input", input: input };
}
exports.roomInput = roomInput;
function chatRoomTopScroll(roomId) {
    return { type: "chat-room-top-scroll", roomId: roomId };
}
exports.chatRoomTopScroll = chatRoomTopScroll;
function chatRoomChangeHuman(roomId, tenantName) {
    return { type: "chat-room-change-human", roomId: roomId, tenantName: tenantName };
}
exports.chatRoomChangeHuman = chatRoomChangeHuman;
function settingRooms() {
    return {
        type: "send-text-message",
    };
}
exports.settingRooms = settingRooms;
function requestChatHistory(roomId) {
    var query = {};
    query.filter = { room_id: roomId };
    query.sort = ["-created_at"];
    query.include = ["user"];
    return json_resources_1.requestResourceFetch([exports.chatRoomHistoryRequestName, roomId], routes_1.RapiV1RoomsPath + "/" + roomId + "/messages", query);
}
exports.requestChatHistory = requestChatHistory;
function answerQuestion(questionId, content) {
    return {
        type: "answer-question",
        questionId: questionId,
        content: content
    };
}
exports.answerQuestion = answerQuestion;
function downloadMessageSearchCSV(roomId) {
    return {
        type: "download-message-search-csv",
        roomId: roomId
    };
}
exports.downloadMessageSearchCSV = downloadMessageSearchCSV;
function setHideHeader(headerToggle) {
    return {
        type: "set-hide-header",
        headerToggle: headerToggle
    };
}
exports.setHideHeader = setHideHeader;
function scenarioAnswer(questionText, scenarioTemplatesId) {
    return {
        type: "scenario-answer",
        questionText: questionText,
        scenarioTemplatesId: scenarioTemplatesId
    };
}
exports.scenarioAnswer = scenarioAnswer;
function scenarioTopList(roomid) {
    return {
        type: "scenario-top-list",
        roomid: roomid
    };
}
exports.scenarioTopList = scenarioTopList;
function scenarioBackList(roomid) {
    return {
        type: "scenario-back-list",
        roomid: roomid
    };
}
exports.scenarioBackList = scenarioBackList;
function scenarioSurveyAnswered(roomid, answer, scenario_survey_templates_id) {
    return {
        type: "scenario-survey-answered",
        roomid: roomid,
        answer: answer,
        scenario_survey_templates_id: scenario_survey_templates_id
    };
}
exports.scenarioSurveyAnswered = scenarioSurveyAnswered;
var subReducer = reducers_1.subReducersFor();
function loadAndFollowRooms(action, state) {
    var _a;
    var effects = [];
    var responseDetails = json_resources_1.getResourceResponseDetails(action);
    if (!responseDetails.resources)
        return { state: state, effects: effects };
    for (var i = 0; i < responseDetails.resources.length; i++) {
        var resource = responseDetails.resources[i];
        if (resource.type === "rooms") {
            var room = resource;
            effects = sequence_services_1.sequence(effects, action_cable_services_1.actionCableSendMessage("MemberChannel", "follow_room", { room_id: room.id }));
            if (!state.currentViewingRoomId && state.currentWidgetPage === "room") {
                (_a = sequence_services_1.sequenceReduction(effects, room_list_reducer_1.goToRoom(state, room.id)), state = _a.state, effects = _a.effects);
            }
        }
    }
    return { state: state, effects: effects };
}
function loadFirstMessageCommon(action, state) {
    return loadFirstMessage(action, state);
}
exports.loadFirstMessageCommon = loadFirstMessageCommon;
function loadFirstMessage(action, state) {
    var effects = [];
    var responseDetails = json_resources_1.getResourceResponseDetails(action);
    if (!responseDetails.resources)
        return { state: state, effects: effects };
    state.scenarioTemplatesData = [];
    var _loop_1 = function (i) {
        var resource = responseDetails.resources[i];
        if (resource.type === "messages") {
            if (state.toggles.isCreatingANewRoom) {
                state = tslib_1.__assign({}, state);
                state.toggles = tslib_1.__assign({}, state.toggles);
                state.toggles.isCreatingANewRoom = false;
                var roomId_1 = getRelationshipId(resource, "room");
                state.roomInputs = tslib_1.__assign({}, state.roomInputs);
                var newRoomInputText = state.roomInputs[""];
                delete state.roomInputs[""];
                state.newRoomMessagesQueue = state.newRoomMessagesQueue.slice();
                state.newRoomMessagesQueue.map(function (queuedMessage) {
                    var _a;
                    addRelationship(queuedMessage, "room", { type: "rooms", id: roomId_1 });
                    (_a = sequence_services_1.sequenceReduction(effects, exports.sendMessage(state, queuedMessage, roomId_1, state.client_id)), state = _a.state, effects = _a.effects);
                });
                state.newRoomMessagesQueue = [];
                state.roomInputs[roomId_1] = newRoomInputText;
            }
        }
    };
    for (var i = 0; i < responseDetails.resources.length; i++) {
        _loop_1(i);
    }
    return { state: state, effects: effects };
}
function reduceChatRoom(state, action) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var effects = [];
    var scenarioFlg = false;
    if (state.plan_settings.scenario_chat_flg) {
        scenarioFlg = state.plan_settings.scenario_chat_flg;
    }
    switch (action.type) {
        case "complete-request":
            if (action.name[0] === exports.sendMessageRequestName) {
                (_a = sequence_services_1.sequenceReduction(effects, loadAndFollowRooms(action, state)), state = _a.state, effects = _a.effects);
                (_b = sequence_services_1.sequenceReduction(effects, loadFirstMessage(action, state)), state = _b.state, effects = _b.effects);
            }
            if (action.name[0] === exports.sendScenarioMessageRequestName) {
                if (!scenarioFlg)
                    break;
                state = tslib_1.__assign({}, state);
                state.scenarioEndSurveyDisplayedFlg = false;
                (_c = sequence_services_1.sequenceReduction(effects, loadAndFollowRooms(action, state)), state = _c.state, effects = _c.effects);
                (_d = sequence_services_1.sequenceReduction(effects, loadFirstMessage(action, state)), state = _d.state, effects = _d.effects);
                var sid_1 = header_reducer_1.categorySid(state);
                (_e = sequence_services_1.sequenceReduction(effects, exports.scenarioSelectList(state, state.displayScenarioTemplatesId, Number(state.currentViewingRoomId), sid_1)), state = _e.state, effects = _e.effects);
            }
            if (action.name[0] === exports.searchScenarioTemplatesListRequestName) {
                if (!scenarioFlg)
                    break;
                state = tslib_1.__assign({}, state);
                state.scenarioEndSurveyDisplayedFlg = false;
                var responseDetails = json_resources_1.getResourceResponseDetails(action);
                var apiResponse = responseDetails.apiResponse;
                if (!apiResponse || isErrorResponse(apiResponse))
                    break;
                if (responseDetails.resources) {
                    var scenarioTemplatesData = responseDetails.resources[0];
                    if (scenarioTemplatesData && scenarioTemplatesData.type === "scenario_templates") {
                        state.scenarioTemplatesData = scenarioTemplatesData.attributes.data;
                    }
                    if (state.scenarioTemplatesData === null || state.scenarioTemplatesData.length <= 0) {
                        if (!state.scenarioEndSurveyDisplayedFlg) {
                            (_f = sequence_services_1.sequenceReduction(effects, exports.loadScenarioEndSurvey(state, Number(state.currentViewingRoomId))), state = _f.state, effects = _f.effects);
                        }
                    }
                }
                state.chat_categories_disabled = false;
            }
            if (action.name[0] === exports.loadScenarioEndSurveyRequestName) {
                if (!scenarioFlg)
                    break;
                state = tslib_1.__assign({}, state);
                state.scenarioEndSurveyDisplayedFlg = true;
                state.scenarioSurveyTemplateData = null;
                var responseDetails = json_resources_1.getResourceResponseDetails(action);
                var apiResponse = responseDetails.apiResponse;
                if (!apiResponse || isErrorResponse(apiResponse))
                    break;
                if (responseDetails.resources) {
                    var scenarioSurveyTemplateData = responseDetails.resources[0];
                    if (scenarioSurveyTemplateData && scenarioSurveyTemplateData.type === "scenario_survey_templates") {
                        state.scenarioSurveyTemplateData = scenarioSurveyTemplateData.attributes;
                    }
                }
                if (state.scenarioSurveyTemplateData !== null) {
                    if (!state.scenarioSurveyTemplateData.answer_template
                        || state.scenarioSurveyTemplateData.answer_template === null
                        || state.scenarioSurveyTemplateData.answer_template.length === 0) {
                        state.scenarioSurveyTemplateData.answer_template = null;
                        state.scenarioEndSurveyDisplayedFlg = false;
                    }
                    if (!state.scenarioSurveyTemplateData.question || state.scenarioSurveyTemplateData.question === null) {
                        state.scenarioSurveyTemplateData.question = null;
                        state.scenarioEndSurveyDisplayedFlg = false;
                    }
                }
                else {
                    state.scenarioEndSurveyDisplayedFlg = false;
                }
            }
            if (action.name[0] === exports.createScenarioEndSurveyAnswerRequestName) {
            }
            if (action.name[0] === initial_loading_reducer_1.loadCustomerRoomsRequestName) {
                state = tslib_1.__assign({}, state);
                state.chat_categories_disabled = true;
                (_g = sequence_services_1.sequenceReduction(effects, loadAndFollowRooms(action, state)), state = _g.state, effects = _g.effects);
                if (scenarioFlg && state.chat_first_display) {
                    state.chat_first_display = false;
                    if (state.inputs.operatorSelectedCategories1Value !== undefined
                        && state.inputs.operatorSelectedCategories1Value !== null
                        && state.inputs.operatorSelectedCategories1Value !== ""
                        && state.currentViewingRoomId !== undefined
                        && state.currentViewingRoomId !== null
                        && state.currentViewingRoomId !== "") {
                        effects = sequence_services_1.sequence(effects, room_list_reducer_1.selectedCategory1(state.client_id, state.inputs.operatorSelectedCategories1Value));
                    }
                    else {
                        state.operatorSelectedCategoriesSid = 0;
                        (_h = sequence_services_1.sequenceReduction(effects, exports.getScenarioTemplateData(state, state.currentViewingRoomId)), state = _h.state, effects = _h.effects);
                    }
                }
                else {
                    state.chat_categories_disabled = false;
                }
            }
            if (action.name[0] === paged_search_requests_reducer_1.downloadCsvRequestName) {
                if (!action.success)
                    break;
                var details = json_resources_1.getResourceResponseDetails(action);
                if (!details.resources)
                    break;
                if (details.resources.length === 0)
                    break;
                var exportData = details.resources[0];
                if (exportData.url.startsWith("/")) {
                    if (action.name[1] === "messages") {
                        state = tslib_1.__assign({}, state);
                        state.localCsvDownloadUrl = tslib_1.__assign({}, state.localCsvDownloadUrl);
                        state.localCsvDownloadUrl.roomMessages = exportData.url;
                    }
                }
                effects = sequence_services_1.sequence(effects, csv_service_1.downloadS3CSV(exportData.url));
            }
            if (action.name[0] === exports.sendTrackerEntryRequestName) {
                if (!action.success)
                    break;
                var res = JSON.parse(action.response);
                if (res.test && res.test == "OK") {
                    console.log(action.response);
                    var btnElement = window.document.getElementById('widget-canvas-iframe').contentWindow.document.getElementById('change_humen');
                    btnElement.remove();
                    var fileUpElement = window.document.getElementById('widget-canvas-iframe').contentWindow.document.getElementById('upload-file-label');
                    fileUpElement.style.display = "";
                    var userBuddleHeaderElement = window.document.getElementById('widget-canvas-iframe').contentWindow.document.getElementById('oksky-chat-user-buddle-header');
                    userBuddleHeaderElement.style.display = '';
                }
            }
            break;
        case "room-input":
            (_j = subReducer("roomInputs", inputs_reducer_1.reduceInputs)(state, action.input), state = _j.state, effects = _j.effects);
            effects = sequence_services_1.sequence(effects, exports.notifyTyping(state.currentViewingRoomId, action.input.text));
            break;
        case "send-text-message":
            if (!(state.roomInputs[state.currentViewingRoomId] || action.value))
                break;
            state = tslib_1.__assign({}, state);
            state.isMessageSendingByRoom = tslib_1.__assign({}, state.isMessageSendingByRoom);
            state.isMessageSendingByRoom[state.currentViewingRoomId] = true;
            var message = newResource("messages");
            message.attributes.content = action.value || state.roomInputs[state.currentViewingRoomId];
            message.attributes.kind = "text";
            (_k = sequence_services_1.sequenceReduction(effects, exports.sendMessage(state, message, state.currentViewingRoomId, state.client_id)), state = _k.state, effects = _k.effects);
            effects = sequence_services_1.sequence(effects, google_analytics_services_1.sendGoogleAnalyticsEvent("Send WidgetMessage by Customer", 'ok-sky_chat', state.clientCode));
            break;
        case "chat-room-top-scroll":
            effects = sequence_services_1.sequence(effects, complete_request_reducer_1.loadNextPageIfNeeded(state, array_utils_1.stringifyArray([exports.chatRoomHistoryRequestName, action.roomId])));
            break;
        case "answer-question":
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourcePut([exports.answerQuestionRequestName, action.questionId, action.content], routes_1.RapiV1QuestionsPath + ("/" + action.questionId + "/answer_question?answer=" + action.content)));
            break;
        case "download-message-search-csv":
            var roomId = action.roomId;
            var room = bisect_1.Indexer.getFirstMatching(state.stores.rooms.byId, [roomId]);
            if (!room)
                break;
            var query = {
                filter: { room_id: roomId },
                file_name: room.attributes.name + ".csv"
            };
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([paged_search_requests_reducer_1.downloadCsvRequestName, "messages"], "/rapi/v1/messages/export_s3.csv", query));
            break;
        case "set-hide-header":
            state = tslib_1.__assign({}, state);
            state.hideHeader = action.headerToggle;
            break;
        case "chat-room-change-human":
            state = tslib_1.__assign({}, state);
            effects = sequence_services_1.sequence(effects, exports.requestChangeHuman(action.roomId, action.tenantName));
            break;
        case "scenario-answer":
            if (!scenarioFlg)
                break;
            if (!(action.scenarioTemplatesId || action.questionText))
                break;
            state = tslib_1.__assign({}, state);
            state.scenarioTemplatesData = [];
            state.isMessageSendingByRoom = tslib_1.__assign({}, state.isMessageSendingByRoom);
            state.isMessageSendingByRoom[state.currentViewingRoomId] = true;
            state.displayScenarioTemplatesId = action.scenarioTemplatesId;
            state.scenarioQuestionText = action.questionText;
            state.scenarioTemplatesId = action.scenarioTemplatesId;
            state.scenarioEndSurveyAnsweredFlg = false;
            state.scenarioEndSurveyDisplayedFlg = false;
            effects = sequence_services_1.sequence(effects, show_video_tag_services_1.createShowVideoTagRootUrl([exports.scenarioMessageRootUrlRequestName]));
            break;
        case "response-show-video-tag-root-url":
            if (action.name[0] === exports.scenarioMessageRootUrlRequestName) {
                var scenario_message = newResource("messages");
                scenario_message.attributes.content = state.scenarioQuestionText;
                scenario_message.attributes.kind = "text";
                scenario_message.attributes.root_url = action.url;
                (_l = sequence_services_1.sequenceReduction(effects, exports.sendScenarioMessage(state, scenario_message, state.currentViewingRoomId, state.client_id, state.scenarioTemplatesId)), state = _l.state, effects = _l.effects);
                effects = sequence_services_1.sequence(effects, google_analytics_services_1.sendGoogleAnalyticsEvent("Send WidgetMessage by Customer", 'ok-sky_chat', state.clientCode));
                state.scenarioQuestionText = null;
                state.scenarioTemplatesId = null;
            }
            break;
        case "scenario-top-list":
            if (!scenarioFlg)
                break;
            state = tslib_1.__assign({}, state);
            state.scenarioEndSurveyDisplayedFlg = false;
            state.scenarioEndSurveyAnsweredFlg = false;
            var sid = header_reducer_1.categorySid(state);
            (_m = sequence_services_1.sequenceReduction(effects, exports.scenarioSelectList(state, 0, action.roomid, sid)), state = _m.state, effects = _m.effects);
            break;
        case "scenario-back-list":
            if (!scenarioFlg)
                return;
            state = tslib_1.__assign({}, state);
            state.scenarioEndSurveyDisplayedFlg = false;
            state.scenarioEndSurveyAnsweredFlg = false;
            var targetRoomidIndx = state.selectedScenarioTemplatesIds.findIndex(function (item) { return item.roomid === action.roomid; });
            if (targetRoomidIndx < 0) {
                state.selectedScenarioTemplatesIds.push({ roomid: action.roomid, ids: [] });
                targetRoomidIndx = state.selectedScenarioTemplatesIds.length - 1;
            }
            if (state.selectedScenarioTemplatesIds[targetRoomidIndx].ids.length > 0) {
                state.selectedScenarioTemplatesIds[targetRoomidIndx].ids.pop();
            }
            (_o = exports.getScenarioTemplateData(state, String(action.roomid)), state = _o.state, effects = _o.effects);
            break;
        case "scenario-survey-answered":
            if (!scenarioFlg)
                return;
            state = tslib_1.__assign({}, state);
            state.scenarioEndSurveyAnsweredFlg = false;
            state.scenarioEndSurveyDisplayedFlg = false;
            if (state.scenarioSurveyTemplateData.thank_you_message && state.scenarioSurveyTemplateData.thank_you_message !== null) {
                state.scenarioEndSurveyAnsweredFlg = true;
            }
            var scenarioSurveyAnswer = newResource("scenario_survey_answers");
            scenarioSurveyAnswer.attributes.answer = String(action.answer);
            scenarioSurveyAnswer.attributes.room_id = action.roomid;
            scenarioSurveyAnswer.attributes.scenario_survey_template_id = state.scenarioSurveyTemplateData.id;
            scenarioSurveyAnswer.attributes.user_id = state.loggedInUser.id;
            (_p = exports.createScenarioEndSurveyAnswer(state, scenarioSurveyAnswer), state = _p.state, effects = _p.effects);
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceChatRoom = reduceChatRoom;
exports.requestChangeHuman = function (roomId, tenantName) {
    var u = newResource("tracker_entrys");
    u.attributes = {
        "roomId": roomId,
        "schema": tenantName,
    };
    return json_resources_1.requestResourceWrite([exports.sendTrackerEntryRequestName], u, routes_1.RapiV1WidgetChangeHuman);
};
exports.getScenarioTemplateData = function (state, roomId) {
    var _a, _b, _c;
    var effects = [];
    if (!state.plan_settings.scenario_chat_flg) {
        return { state: state, effects: effects };
    }
    if (state.selectedScenarioTemplatesIds === null) {
        state.selectedScenarioTemplatesIds = [];
    }
    var sid = header_reducer_1.categorySid(state);
    if (roomId) {
        var selectedScenarioTemplatesIds = state.selectedScenarioTemplatesIds.filter(function (item) { return item.roomid === Number(roomId); });
        var ids = selectedScenarioTemplatesIds.length > 0 ? selectedScenarioTemplatesIds[0].ids : [];
        if (ids.length > 0) {
            var selectid = ids.pop();
            (_a = sequence_services_1.sequenceReduction(effects, exports.scenarioSelectList(state, selectid, Number(roomId), sid)), state = _a.state, effects = _a.effects);
        }
        else {
            (_b = sequence_services_1.sequenceReduction(effects, exports.scenarioSelectList(state, 0, Number(roomId), sid)), state = _b.state, effects = _b.effects);
        }
    }
    else {
        (_c = sequence_services_1.sequenceReduction(effects, exports.scenarioSelectList(state, 0, null, sid)), state = _c.state, effects = _c.effects);
    }
    return { state: state, effects: effects };
};
exports.loadScenarioEndSurvey = function (state, roomid) {
    var effects = [];
    var scenarioFlg = false;
    if (state.plan_settings.scenario_chat_flg) {
        scenarioFlg = state.plan_settings.scenario_chat_flg;
    }
    if (!scenarioFlg)
        return { state: state, effects: effects };
    var query = {
        roomId: roomid,
    };
    effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([exports.loadScenarioEndSurveyRequestName], routes_1.RapiV1ScenarioSurveyTemplatesPath + "/get_end_survey", query));
    return { state: state, effects: effects };
};
exports.createScenarioEndSurveyAnswer = function (state, scenarioSurveyAnswer) {
    var effects = [];
    var scenarioFlg = false;
    if (state.plan_settings.scenario_chat_flg) {
        scenarioFlg = state.plan_settings.scenario_chat_flg;
    }
    if (!scenarioFlg)
        return { state: state, effects: effects };
    effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceWrite([exports.createScenarioEndSurveyAnswerRequestName], scenarioSurveyAnswer, routes_1.RapiV1ScenarioSurveyAnswersPath));
    return { state: state, effects: effects };
};
exports.scenarioSelectList = function (state, id, roomid, sid) {
    var effects = [];
    var scenarioFlg = false;
    if (state.plan_settings.scenario_chat_flg) {
        scenarioFlg = state.plan_settings.scenario_chat_flg;
    }
    if (!scenarioFlg)
        return { state: state, effects: effects };
    if (roomid !== null) {
        var targetRoomidIndx = -1;
        if (state.selectedScenarioTemplatesIds !== undefined && state.selectedScenarioTemplatesIds !== null) {
            targetRoomidIndx = state.selectedScenarioTemplatesIds.findIndex(function (item) { return item.roomid === roomid; });
        }
        else {
            state.selectedScenarioTemplatesIds = [];
        }
        if (targetRoomidIndx < 0) {
            state.selectedScenarioTemplatesIds.push({ roomid: roomid, ids: [] });
            targetRoomidIndx = state.selectedScenarioTemplatesIds.length - 1;
        }
        state.operatorSelectedCategoriesSid = sid;
        if (id === 0) {
            state.selectedScenarioTemplatesIds[targetRoomidIndx].ids = [];
        }
        else {
            state.selectedScenarioTemplatesIds[targetRoomidIndx].ids.push(id);
        }
    }
    state.scenarioTemplatesData = [];
    state.displayScenarioTemplatesId = null;
    var query = {
        scenarioTemplatesId: id,
        sid: sid,
    };
    effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([exports.searchScenarioTemplatesListRequestName], routes_1.RapiV1ScenarioTemplatesPath + "/search_list", query));
    return { state: state, effects: effects };
};
exports.notifyTyping = function (roomId, text) {
    var effects = [];
    if (!roomId)
        return;
    effects = sequence_services_1.sequence(effects, action_cable_services_1.actionCableSendMessage("MemberChannel", "notify_typing", {
        room_id: roomId,
        text: text
    }));
    return effects;
};
exports.sendMessage = function (state, message, roomId, clientId) {
    var effects = [];
    addRelationship(message, "user", { type: "users", id: state.loggedInUser.id });
    var url = routes_1.RapiV1MessagesPath;
    if (roomId) {
        var room = bisect_1.Indexer.getFirstMatching(state.stores.rooms.byId, [roomId]);
        if (room)
            addRelationship(message, "room", room);
    }
    else if (clientId) {
        url += "?client_id=" + clientId;
    }
    else {
        console.error("Unable to send message need either a roomId or a clientId to send");
    }
    state = tslib_1.__assign({}, state);
    state.roomInputs = tslib_1.__assign({}, state.roomInputs);
    state.roomInputs[roomId] = "";
    if (!roomId) {
        if (state.toggles.isCreatingANewRoom) {
            state.newRoomMessagesQueue = state.newRoomMessagesQueue.slice();
            state.newRoomMessagesQueue.push(message);
            return { state: state, effects: effects };
        }
        else {
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.isCreatingANewRoom = true;
        }
    }
    effects = sequence_services_1.sequence(effects, exports.notifyTyping(roomId, ""));
    effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceWrite([exports.sendMessageRequestName], message, url));
    return { state: state, effects: effects };
};
exports.sendScenarioMessage = function (state, message, roomId, clientId, scenarioTemplateId) {
    var effects = [];
    var scenarioFlg = false;
    if (state.plan_settings.scenario_chat_flg) {
        scenarioFlg = state.plan_settings.scenario_chat_flg;
    }
    if (!scenarioFlg)
        return { state: state, effects: effects };
    addRelationship(message, "user", { type: "users", id: state.loggedInUser.id });
    var url = routes_1.RapiV1MessagesPath + "/scenario_select_create";
    if (roomId) {
        var room = bisect_1.Indexer.getFirstMatching(state.stores.rooms.byId, [roomId]);
        if (room)
            addRelationship(message, "room", room);
    }
    else if (clientId) {
        url += "?client_id=" + clientId;
    }
    else {
        console.error("Unable to send message need either a roomId or a clientId to send");
    }
    state = tslib_1.__assign({}, state);
    state.roomInputs = tslib_1.__assign({}, state.roomInputs);
    state.roomInputs[roomId] = "";
    message.attributes.scenario_select_id = scenarioTemplateId;
    message.attributes.chat_category1 = state.inputs.operatorSelectedCategories1Value;
    message.attributes.chat_category2 = state.inputs.operatorSelectedCategories2Value;
    if (!roomId) {
        if (state.toggles.isCreatingANewRoom) {
            state.newRoomMessagesQueue = state.newRoomMessagesQueue.slice();
            state.newRoomMessagesQueue.push(message);
            return { state: state, effects: effects };
        }
        else {
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.isCreatingANewRoom = true;
        }
    }
    effects = sequence_services_1.sequence(effects, exports.notifyTyping(roomId, ""));
    effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceWrite([exports.sendScenarioMessageRequestName], message, url));
    return { state: state, effects: effects };
};
exports.answerQuestionRequestName = "answer-question-request-name";
exports.sendMessageRequestName = "send-message-request-name";
exports.sendScenarioMessageRequestName = "send-scenario-message-request-name";
exports.chatRoomHistoryRequestName = "chat-room-history";
exports.sendTrackerEntryRequestName = "send-tracker-entry-request-name";
exports.searchScenarioTemplatesListRequestName = "search-scenario-templates-list-request-name";
exports.loadScenarioEndSurveyRequestName = "load-scenario-end-survey-request-name";
exports.createScenarioEndSurveyAnswerRequestName = "create-scenario-end-survey-answer-request-name";
exports.scenarioMessageRootUrlRequestName = "scenario-message-root-url-request-name";
exports.chatRoomPagedRequestNames = [exports.chatRoomHistoryRequestName];
