"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var stores_1 = require("../../stores");
var WidgetV2State;
(function (WidgetV2State) {
    var userWidgetSettingsPageToggles = {
        getEmailUpdates: true,
    };
    WidgetV2State.initialState = {
        loggedInUser: { attributes: {} },
        client_id: "",
        auto_posts: [],
        notification_before_closing_business_hour: "",
        v2_time_format: "",
        description: "",
        reply_guidance: "",
        header_title: "",
        header_description: "",
        create_room_button_text: "",
        button_background_url: "",
        widget_position: "bottom_right",
        collect_emails_when_not_during_business_hours: null,
        hide_download_link: true,
        clientCode: "",
        provider_login_mode: false,
        schedules: [],
        app_settings: {},
        collect_emails_title: "",
        collect_emails_description: "",
        collect_emails_error: "",
        xd_cookie_names: [],
        is_aws_present: false,
        colors: {
            widget_button: "#5ab2dd",
            widget_button_fill: "#ffffff",
            widget_hide_button: "#5ab2dd",
            widget_hide_button_fill: "#ffffff",
            background: "#fff",
            background_text: "#adb0b7",
            send_message_button_outline: "#1f90d6",
            message_date_text: "#838485",
            operator_message_background: "#e8e8e8",
            operator_message_text: "#000",
            customer_message_background: "#5ab2dd",
            customer_message_text: "#fff",
            widget_csv_download_button_background: "#fff",
            widget_csv_download_button_fill: "#000",
            save_settings_button_background: "#5ab2dd",
            save_settings_button_text: "#fff",
            navigation_bar_background: "#5ab2dd",
            navigation_bar_text: "#fff",
            navigation_bar_button_fill: "#fff",
            room_list_room_background: "#fff",
            room_list_room_text: "#adb0b7",
            bottom_action_button_background: "#5ab2dd",
            bottom_action_button_fill: "#fff",
            progress_bar: "#5ab2dd",
            unread_message_background: "#eb4d5c",
            unread_message_text: "#fff",
            scrollbar_background: "#ccc",
            scrollbar_body: "#000",
            settings_text: "#2E343D",
            settings_subtext: "#A0A0A3",
            outline: "#add8e6",
            collect_emails_background: "#fff",
            collect_emails_text: "#2e343d",
            collect_emails_error: "#e7040f",
            collect_emails_description: "#A0A0A3",
        },
        tenant_name: "",
        thisTenant: {},
        inBusinessHours: false,
        inputs: {
            userEmailInput: "",
            customerLanguage: "",
            operatorSelectedCategories1Value: "",
            operatorSelectedCategories2Value: "",
        },
        operatorSelectedCategoriesSid: 0,
        roomInputs: {},
        roomNotifications: [],
        previousWidgetPages: [],
        currentWidgetPage: "room",
        toggles: tslib_1.__assign({ widgetHidden: false, widgetMaximized: false, widgetFocused: false, isCreatingANewRoom: false, forceHidePopup: false }, userWidgetSettingsPageToggles),
        sizes: {
            windowSize: [window.innerWidth, window.innerHeight],
        },
        flashes: {
            connectionWarning: null,
            connectionRestored: null,
            requestError: null,
            success: null,
        },
        errorMessages: {
            widgetSettingsPage: {
                customerNotificationEmail: false
            },
        },
        connectionErrors: {},
        now: Date.now(),
        relativeNow: 0,
        isMessageSendingByRoom: {},
        fakeWidget: {
            inEditMode: false,
            initSwitch: false,
        },
        widgetScreenMinHeightPx: 512,
        widgetScreenMinWidthPx: 480,
        widgetPopupMinHeightPx: 256,
        isIOS: (!!navigator.platform && /iPhone/.test(navigator.platform)) || (/iPhone/.test(navigator.userAgent) && !window.MSStream),
        isAndroid: (!!navigator.platform && /Linux/.test(navigator.platform)) || (/Android/.test(navigator.userAgent) && !window.MSStream),
        numberOfOperatorsShownOnHeader: 3,
        popupMessageIds: [],
        initialized: false,
        currentViewingRoomId: "",
        currentViewingRoomName: "",
        stores: stores_1.Stores.initialState,
        localCsvDownloadUrl: { roomMessages: "" },
        nextPagedRequests: {},
        loadingPagedRequests: {},
        isRoomMarkingRead: {},
        roomListRows: [],
        newRoomMessagesQueue: [],
        hideHeader: false,
        displayScenarioTemplatesId: null,
        scenarioQuestionText: null,
        scenarioTemplatesId: null,
        selectedScenarioTemplatesIds: null,
        scenarioTemplatesData: [],
        scenarioEndSurveyDisplayedFlg: false,
        scenarioEndSurveyAnsweredFlg: false,
        scenarioSurveyTemplateData: null,
        plan_settings: {},
        tracker_check_get_message_ids: [],
        buttonImageStyles: {},
        chat_categories: {},
        chat_categories_disabled: false,
        chat_first_display: true,
    };
})(WidgetV2State = exports.WidgetV2State || (exports.WidgetV2State = {}));
