"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var members_store_1 = require("./members-store");
var memos_store_1 = require("./memos-store");
var messages_store_1 = require("./messages-store");
var rooms_store_1 = require("./rooms-store");
var users_store_1 = require("./users-store");
var supports_store_1 = require("./supports-store");
var master_keywords_store_1 = require("./master-keywords-store");
var template_responses_store_1 = require("./template-responses-store");
var suggestions_store_1 = require("./suggestions-store");
var draft_messages_store_1 = require("./draft-messages-store");
var tracker_entries_store_1 = require("./tracker-entries-store");
var user_events_store_1 = require("./user-events-store");
var clients_store_1 = require("./clients-store");
var hooks_store_1 = require("./hooks-store");
var external_services_store_1 = require("./external-services-store");
var reports_store_1 = require("./reports-store");
var tenants_store_1 = require("./tenants-store");
var visits_store_1 = require("./visits-store");
var surveys_store_1 = require("./surveys-store");
var questions_store_1 = require("./questions-store");
var user_widget_settings_store_1 = require("./user-widget-settings-store");
var exports_store_1 = require("./exports-store");
var stripe_plans_store_1 = require("./stripe-plans-store");
var stripe_products_store_1 = require("./stripe-products-store");
var stripe_cards_store_1 = require("./stripe-cards-store");
var stripe_customers_store_1 = require("./stripe-customers-store");
var stripe_subscriptions_store_1 = require("./stripe-subscriptions-store");
var record_range_1 = require("./../utils/record-range");
var marketing_datas_store_1 = require("./marketing-datas-store");
var scenario_templates_store_1 = require("./scenario-templates-store");
var scenario_survey_templates_store_1 = require("./scenario-survey-templates-store");
var chat_categories_store_1 = require("./chat-categories-store");
var chat_all_categories_store_1 = require("./chat-all-categories-store");
var indexerByModelType = {};
exports.stateNameByModelType = {};
function findStatePropName(modelType, indexer) {
    var propName = exports.stateNameByModelType[modelType];
    if (propName)
        return propName;
    for (var nextName in Stores.initialState) {
        if (indexer.matchesInitialState(Stores.initialState[nextName])) {
            if (propName != null) {
                throw new Error("Index for " + propName + " was bound twice!  Typo in stores/index?");
            }
            propName = nextName;
        }
    }
    return (exports.stateNameByModelType[modelType] = propName);
}
function bindIndexer(indexer, types) {
    types.forEach(function (type) { return indexerByModelType[type] = indexer; });
    return indexer.empty();
}
var Stores;
(function (Stores) {
    Stores.initialState = {
        userWidgetSettings: bindIndexer(user_widget_settings_store_1.UserWidgetSettingsStore.indexer, ["user_widget_settings"]),
        rooms: bindIndexer(rooms_store_1.RoomsStore.indexer, ["rooms"]),
        members: bindIndexer(members_store_1.MembersStore.indexer, ["members"]),
        memos: bindIndexer(memos_store_1.MemosStore.indexer, ["memos"]),
        messages: bindIndexer(messages_store_1.MessagesStore.indexer, ["messages"]),
        reports: bindIndexer(reports_store_1.ReportsStore.indexer, ["reports"]),
        supports: bindIndexer(supports_store_1.SupportsStore.indexer, ["supports"]),
        templateResponses: bindIndexer(template_responses_store_1.TemplateResponsesStore.indexer, ["template_responses"]),
        masterKeywords: bindIndexer(master_keywords_store_1.MasterKeywordsStore.indexer, ["master_keywords"]),
        users: bindIndexer(users_store_1.UsersStore.indexer, ["users"]),
        suggestions: bindIndexer(suggestions_store_1.SuggestionsStore.indexer, ["suggestions"]),
        newResources: [],
        removedResources: [],
        draftMessageResources: bindIndexer(draft_messages_store_1.DraftMessageStore.indexer, ["draft_messages"]),
        trackerEntries: bindIndexer(tracker_entries_store_1.TrackerEntriesStore.indexer, ["tracker_entries"]),
        userEvents: bindIndexer(user_events_store_1.UserEventsStore.indexer, ["user_events"]),
        clients: bindIndexer(clients_store_1.ClientsStore.indexer, ["clients"]),
        hooks: bindIndexer(hooks_store_1.HooksStore.indexer, ["hooks"]),
        externalServices: bindIndexer(external_services_store_1.ExternalServicesStore.indexer, ["external_services"]),
        visits: bindIndexer(visits_store_1.VisitsStore.indexer, ["visits"]),
        surveys: bindIndexer(surveys_store_1.SurveysStore.indexer, ["surveys"]),
        questions: bindIndexer(questions_store_1.QuestionsStore.indexer, ["questions"]),
        tenants: bindIndexer(tenants_store_1.TenantsStore.indexer, ["tenants"]),
        exports: bindIndexer(exports_store_1.ExportsStore.indexer, ["exports"]),
        stripePlans: bindIndexer(stripe_plans_store_1.StripePlansStore.indexer, ["stripe_plans"]),
        stripeProducts: bindIndexer(stripe_products_store_1.StripeProductsStore.indexer, ["stripe_products"]),
        stripeCards: bindIndexer(stripe_cards_store_1.StripeCardsStore.indexer, ["stripe_cards"]),
        stripeCustomers: bindIndexer(stripe_customers_store_1.StripeCustomersStore.indexer, ["stripe_customers"]),
        stripeSubscriptions: bindIndexer(stripe_subscriptions_store_1.StripeSubscriptionsStore.indexer, ["stripe_subscriptions"]),
        marketingDatas: bindIndexer(marketing_datas_store_1.MarketingDatasStore.indexer, ["marketing_datas"]),
        scenarioTemplates: bindIndexer(scenario_templates_store_1.ScenarioTemplatesStore.indexer, ["scenario_templates"]),
        ScenarioSurveyTemplates: bindIndexer(scenario_survey_templates_store_1.ScenarioSurveyTemplatesStore.indexer, ["scenario_survey_templates"]),
        chatCategories: bindIndexer(chat_categories_store_1.ChatCategoriesStore.indexer, ["chat_categories"]),
        chatAllCategories: bindIndexer(chat_all_categories_store_1.ChatAllCategoriesStore.indexer, ["chat_all_categories"]),
        recordRanges: {
            messages: [],
        },
        recordPositions: {
            messages: [],
        },
    };
    function loadResources(data, state) {
        var dataByTargetIndexName = {};
        data.forEach(function (resource) {
            var targetIndexer = indexerByModelType[resource.type];
            if (targetIndexer == null) {
                throw new Error("Could not find an targetIndexer for type " +
                    resource.type +
                    ".  Missing from stores/index?");
            }
            var targetIndexName = findStatePropName(resource.type, targetIndexer);
            if (!dataByTargetIndexName[targetIndexName]) {
                dataByTargetIndexName[targetIndexName] = [];
            }
            dataByTargetIndexName[targetIndexName].push(resource);
        });
        state = tslib_1.__assign({}, state);
        for (var indexName in dataByTargetIndexName) {
            var resources = dataByTargetIndexName[indexName];
            var targetIndexer = indexerByModelType[resources[0].type];
            if (indexName === "messages") {
                state = record_range_1.recordRangeMessages(state, resources);
            }
            state[indexName] = targetIndexer.update(state[indexName], resources);
        }
        state.newResources = state.newResources.concat(data);
        return state;
    }
    Stores.loadResources = loadResources;
    function removeResource(id, state) {
        var targetIndexer = indexerByModelType[id.type];
        if (targetIndexer == null) {
            throw new Error("Could not find an targetIndexer for type " +
                id.type +
                ".  Missing from stores/index?");
        }
        var targetIndexName = findStatePropName(id.type, targetIndexer);
        state = tslib_1.__assign({}, state);
        state[targetIndexName] = targetIndexer.removeByPk(state[targetIndexName], [id.id]);
        state.removedResources = state.removedResources.concat([id]);
        return state;
    }
    Stores.removeResource = removeResource;
})(Stores = exports.Stores || (exports.Stores = {}));
